import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Layout from '../../components/layout/layout';
import { RichContentSections } from '../../components';
import scholarshipWinnerPageStyles from './scholarshipWinnerPage.module.scss';

const ScholarshipWinnerPage = ({ data }) => {
    const { title, date } = scholarshipWinnerPageStyles;
    const {
        metaTitle, metaDescription, richContentSections, city, year, season,
    } = data.graphCMS.scholarshipWinnerPage;
    // need to check for a state for the Washington, DC case since it doesn't have one
    const stateSlug = _.get(city, 'state.slug');

    return (
        <Layout metaTitle={metaTitle} metaDescription={metaDescription}>
            <div>
                <h1 className={title} data-testid="title">
                    {`${city.name}${stateSlug ? `, ${_.toUpper(stateSlug)}` : ''} Student Earns The WikiLawn Grass For Class Scholarship`}
                </h1>
                <h3 className={date}>{`${season} ${year}`}</h3>
                { richContentSections ? <RichContentSections sections={richContentSections} /> : null }
            </div>
        </Layout>
    );
};

export const pageQuery = graphql`
  query($id: ID!) {
    graphCMS {
      scholarshipWinnerPage(where: { id: $id }) {
        id
        metaTitle
        metaDescription
        winnerName
        year
        season
        city {
            name
            state {
                slug
            }
        }
        richContentSections {
            id
            heading
            body
            image {
                url
                caption
                localFile {
                    childImageSharp {
                        fluid(maxWidth: 600, maxHeight: 600, quality: 75) {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
          }
      }
    }
  }
`;

ScholarshipWinnerPage.propTypes = {
    data: PropTypes.shape({
        graphCMS: PropTypes.shape({
            scholarshipWinnerPage: PropTypes.shape({
                id: PropTypes.string,
                metaTitle: PropTypes.string,
                metaDescription: PropTypes.string,
                winnerName: PropTypes.string,
                year: PropTypes.number,
                season: PropTypes.string,
                city: PropTypes.shape({
                    name: PropTypes.string,
                    state: PropTypes.shape({
                        slug: PropTypes.string,
                    }),
                }),
                richContentSections: PropTypes.arrayOf(PropTypes.shape({
                    navText: PropTypes.string,
                    heading: PropTypes.string,
                    body: PropTypes.string,
                    image: PropTypes.shape({
                        caption: PropTypes.string,
                        alt: PropTypes.string,
                        localFile: PropTypes.shape({
                            childImageSharp: PropTypes.shape({
                                fluid: PropTypes.object,
                            }).isRequired,
                        }).isRequired,
                    }),
                })),
            }).isRequired,
        }).isRequired,
    }).isRequired,
};

export default ScholarshipWinnerPage;
